<template>
  <div>
    <XeInputDropdown
      :value="inputValue"
      :dropdown-label="dropdownLabel"
      :options="options"
      option-label="text"
      option-value="value"
      :placeholder="placeholder"
      @input="onOptionSelected($event)"
    />

    <XeTextarea
      v-if="isCustomInputShown"
      v-model="customInputText"
      :label="label"
      :placeholder="customInputPlaceholder"
      :validation="validation"
      @input="onCustomInputChange"
    />
    <AppInputText
      v-if="isTextBoxShown"
      v-model="customInputText"
      type="text"
      :label="label"
      :placeholder="customInputPlaceholder"
      :validation="validation"
      @input="onCustomInputChange"
    />

    <!-- Dynamic components based on the option selection -->
    <AppDynamicForm v-if="formFields" :fields="formFields" />
  </div>
</template>

<script>
import { ref, onMounted, reactive, computed, watch } from '@vue/composition-api'
import XeTextarea from '@/components/XeTextarea/XeTextarea'
import XeInputDropdown from '@/components/XeInputDropdown/XeInputDropdown'
import AppInputText from '@/components/AppInputText/AppInputText'
import AppDynamicForm from '@/components/AppDynamicForm/AppDynamicForm.vue'
import AppDynamicFormField from '@/models/DynamicField/AppDynamicFormField'
import XeField from '@/models/DynamicField/XeField'
import { useDynamicForm } from '@/forms/useDynamicForm'
import { useValidation } from 'vue-composable'
import {
  useFieldComponentHelper,
  useCorporateFieldPropsHelper,
  useCorporateFieldValidationHelper,
} from '@/composables/useFormHelpers'

export default {
  components: {
    XeInputDropdown,
    XeTextarea,
    AppInputText,
    AppDynamicForm,
  },
  emit: ['input', 'isCustomFieldInvalid'],
  props: {
    dropdownLabel: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    otherPlaceholder: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    validation: {
      type: [Object, Array],
      default: () => ({}),
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props, { emit }) {
    const inputValue = ref(props.value)
    const isCustomInputShown = ref(false)
    const isTextBoxShown = ref(false)
    const customInputText = ref('')
    const label = ref('')
    const customInputPlaceholder = ref('')
    const selectedOption = ref('')
    const selectedField = ref(null)
    const validationData = reactive({})
    const { formValidation, formFields, isFormInvalid } = useDynamicForm()

    const onOptionSelected = (selectedCode) => {
      selectedOption.value = props.options.find((option) => option.value === selectedCode)
      isCustomInputShown.value = false
      isTextBoxShown.value = false
      inputValue.value = selectedCode
      label.value = selectedOption.value.freeTextLabel
      customInputPlaceholder.value = props.otherPlaceholder
      customInputText.value = ''

      if (selectedOption.value.validation) {
        // Create field based on the selected option
        setupCustomFieldComponent()
      } else {
        // Hide fields
        formFields.value = []
      }

      if (selectedOption.value.allowFreeText) {
        isCustomInputShown.value = true
        emit('input', '')
      } else if (selectedOption.value.allowTextBox) {
        customInputPlaceholder.value = selectedOption.value.freeTextPlaceholder
        isTextBoxShown.value = true
        emit('input', '')
      } else {
        emit('input', selectedCode)
        if (selectedOption.value.followUps) {
          emit('custom-event', 'follow-ups', selectedOption.value.followUps)
        } else {
          emit('custom-event', 'clean-follow-ups')
        }
      }
    }

    const onCustomInputChange = (text) => {
      customInputText.value = text
      if (!text) {
        emit('input', '')
      } else {
        emit('input', `${inputValue.value} - ${text}`)
        // emit('input', `${text}`)
      }
    }

    watch(isFormInvalid, (isInvalid) => {
      emit('isCustomFieldInvalid', isInvalid)
    })

    onMounted(() => {
      let [optionText, value] = props.value.split(' - ')
      selectedOption.value = props.options.find((option) => option.text === optionText)
      if (selectedOption.value) {
        isCustomInputShown.value = selectedOption.value.allowFreeText
        isTextBoxShown.value = selectedOption.value.allowTextBox
        customInputText.value = value
        customInputPlaceholder.value = selectedOption.value.freeTextPlaceholder
        label.value = selectedOption.value.freeTextLabel
        inputValue.value = optionText

        if (selectedOption.value.validation) {
          setupCustomFieldComponent(value)
        }
      }
    })

    const createFields = (name, options) => {
      const field = new XeField(name, options)
      const dynamicField = new AppDynamicFormField({
        field: field,
        value: '',
        hidden: false,
        disabled: false,
        props: useCorporateFieldPropsHelper(options),
        component: useFieldComponentHelper(options.type),
      })

      return reactive(dynamicField)
    }

    const setupCustomFieldComponent = (value) => {
      // Create field based on the selected option
      selectedField.value = createFields(selectedOption.value.freeTextLabel, {
        id: selectedOption.value.freeTextLabel,
        mandatory: true,
        displayname: selectedOption.value.freeTextLabel,
        placeholder: selectedOption.value.freeTextPlaceholder,
        type: selectedOption.value.type,
        validation: selectedOption.value.validation,
      })

      // Create validation
      validationData.value = useCorporateFieldValidationHelper(selectedField.value)
      formValidation.value = useValidation(validationData.value)

      // Set fields and validation
      selectedField.value.value = value || selectedField.value.value
      selectedField.value.validation = formValidation.value

      selectedField.value.listeners = { input: (text) => onCustomInputChange(text) }
      if (selectedField.value) {
        selectedField.value.validation.$dirty = true
      }

      formFields.value = [selectedField.value]
    }

    return {
      inputValue,
      onOptionSelected,
      isCustomInputShown,
      isTextBoxShown,
      customInputText,
      label,
      customInputPlaceholder,
      onCustomInputChange,
      formFields: computed(() => formFields.value),
      formValidation,
      isFormInvalid: computed(() => isFormInvalid.value),
    }
  },
}
</script>

<style lang="postcss" scoped></style>
