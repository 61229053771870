<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <AppInputWrapper
    v-slot="{ input, validationHelpers }"
    :class="{
      'input--with-left-icon': $slots.leftIcon,
      'input--with-right-icon': $slots.rightIcon,
    }"
  >
    <textarea
      v-model="input.model"
      :class="[
        ...input.classes,
        'input-textarea',
        { 'input-textarea-error': validationHelpers.hasError },
      ]"
      v-bind="input.attrs"
      v-on="input.listeners"
    />
    <div v-if="$slots.leftIcon" class="input-left-icon">
      <!-- @slot leftIcon: For the left Icon -->
      <slot name="leftIcon"></slot>
    </div>
    <div v-if="$slots.rightIcon" class="input-right-icon">
      <!-- @slot rightIcon: For the right Icon -->
      <slot name="rightIcon"></slot>
    </div>
  </AppInputWrapper>
</template>

<script>
import AppInputWrapper from '@/components/AppInputWrapper/AppInputWrapper'
import { useMediaQuery } from '@/composables/useMediaQuery'

export default {
  components: {
    AppInputWrapper,
  },
  inheritAttrs: false,
  emits: ['input', 'focus', 'blur'],
  props: {
    value: {
      type: String,
      required: true,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    validation: {
      type: Object,
      default: () => ({}),
    },
    helperText: {
      type: String,
      default: '',
    },
    showCounter: {
      type: Boolean,
      required: false,
      default: false,
    },
    analyticsName: {
      type: String,
      default: '',
      required: !!process.env.VUE_APP_ANALYTICS_ENABLED,
    },
  },
  setup() {
    return {
      mq: useMediaQuery(),
    }
  },
}
</script>

<style scoped>
.input-textarea {
  @apply py-3 h-48;
  resize: none;
  @screen sm {
    @apply h-24;
  }
}

.input-textarea-error {
  border: 1px solid #cd3c64;
}
</style>
