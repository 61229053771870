var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppInputWrapper',{class:{
    'input--with-left-icon': _vm.$slots.leftIcon,
    'input--with-right-icon': _vm.$slots.rightIcon,
  },scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var input = ref.input;
  var validationHelpers = ref.validationHelpers;
return [_c('textarea',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(input.model),expression:"input.model"}],class:input.classes.concat( ['input-textarea'],
      [{ 'input-textarea-error': validationHelpers.hasError }] ),domProps:{"value":(input.model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(input, "model", $event.target.value)}}},'textarea',input.attrs,false),input.listeners)),_vm._v(" "),(_vm.$slots.leftIcon)?_c('div',{staticClass:"input-left-icon"},[_vm._t("leftIcon")],2):_vm._e(),(_vm.$slots.rightIcon)?_c('div',{staticClass:"input-right-icon"},[_vm._t("rightIcon")],2):_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }